import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';
import UseLogger from '../../hooks/useLogger/useLogger';
import { PortalConfig } from '../../models/PortalConfigsModels';
import FailedPortalConfigs from '../../pages/Errors/FailedPortalConfigs';
import PortalConfigsService from '../../services/PortalConfigs/PortalConfigsService';
import { AuthContext } from '../AuthContext/AuthContext';

interface PortalConfigData {
  portalConfig: PortalConfig | undefined;
}
export const PortalConfigContext = createContext({} as PortalConfigData);

function PortalConfigProvider({ children }: PropsWithChildren) {
  const [portalConfig, setPortalConfig] = useState<PortalConfig>();
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetches the portal configurations
   */
  const fetchPortalConfig = () => {
    UseLogger().logInfo('PortalConfigContext: fetching portal config');
    setIsLoading(true);
    PortalConfigsService.api
      .fetchConfigs()
      .then(
        (res) => {
          setPortalConfig(res.data);
        },
        (err) => {
          UseLogger().logError(`PortalConfigContext: error fetching portal config!${JSON.stringify(err)}`);
        },
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (authContext.userData) {
      fetchPortalConfig();
    }
  }, [authContext.userData]);

  const value = useMemo(
    () => ({
      portalConfig,
    }),
    [portalConfig],
  );

  /**
   * Render method
   */
  const render = () => {
    if (isLoading) {
      return <LoadingPage />;
    }
    if (portalConfig) {
      return <PortalConfigContext.Provider value={value}>{children}</PortalConfigContext.Provider>;
    }
    return <FailedPortalConfigs />;
  };

  return <>{render()}</>;
}
export default PortalConfigProvider;
