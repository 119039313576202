import { Box, BoxProps, Link, LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.state.linkHover,
    color: theme.palette.state.linkHover,
  },
}));

const RouterLinkWrapper = styled(RouterLink)<LinkProps>(({ theme }) => ({
  fontSize: '1.125rem',
  fontStyle: 'normal',
  lineHeight: '1.625rem',
  color: theme.palette.state.linkHover,
  textDecoration: 'none',
}));

const LinkWrapper = styled(Link)<LinkProps>(({ theme }) => ({
  fontSize: '1.125rem',
  fontStyle: 'normal',
  lineHeight: '1.625rem',
  color: theme.palette.state.linkHover,
  textDecoration: 'none',
}));

interface ClickableLinkWithIconProps extends PropsWithChildren, LinkProps {
  onClick: (params?: unknown) => unknown;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  to?: string;
  target?: string;
}

function ClickableLinkWithIcon({
  children,
  leftIcon,
  rightIcon,
  onClick,
  to,
  target,
  ...rest
}: ClickableLinkWithIconProps) {
  return (
    <BoxWrapper onClick={onClick}>
      {leftIcon ? <Box sx={{ paddingRight: '0.5rem', display: 'flex', alignItems: 'center' }}>{leftIcon}</Box> : null}
      {to ? (
        <RouterLinkWrapper sx={rest.sx} className={rest.className} to={to} target={target ? '_self' : target}>
          {children}
        </RouterLinkWrapper>
      ) : (
        <LinkWrapper {...rest}>{children}</LinkWrapper>
      )}
      {rightIcon ? <Box sx={{ paddingLeft: '0.5rem', display: 'flex', alignItems: 'center' }}>{rightIcon}</Box> : null}
    </BoxWrapper>
  );
}

export default ClickableLinkWithIcon;
